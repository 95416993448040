@import '../../../../scss/theme-bootstrap';

.hero-block {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 0;
  text-align: $ldirection;
  margin-#{$ldirection}: auto;
  margin-#{$rdirection}: auto;
  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__content,
  &__content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;
    &.round-edge-5px {
      border-radius: 5px;
    }
    &.round-edge-10px {
      border-radius: 10px;
    }
    &.round-edge-15px {
      border-radius: 15px;
    }
    &.round-edge-20px {
      border-radius: 20px;
    }
    &.round-edge-25px {
      border-radius: 25px;
    }
    &.round-edge-30px {
      border-radius: 30px;
    }
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
  }
  &__media-wrapper {
    position: relative;
    text-align: center;
    height: 100%;
    display: flex;
  }
  &__content-over-media {
    display: flex;
    flex-direction: column;
    flex: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    #{$ldirection}: 0;
    #{$rdirection}: 0;
    margin: 0 auto;
    width: 100%;
    .hero-block-no-media & {
      position: relative;
    }
  }
  &__link-wrapper {
    display: flex;
    flex-direction: column;
    &--column {
      display: flex;
      flex-direction: column;
      .hero-block__link-gutter {
        width: 0;
      }
    }
    &--row {
      display: flex;
      flex-direction: row;
      .hero-block__link-gutter {
        height: 0;
      }
    }
    .text-align--left & {
      align-self: flex-start;
    }
    .text-align--right & {
      align-self: flex-end;
    }
    .text-align--center & {
      align-self: center;
    }
  }
  &__content-over-media,
  &__content-wrapper {
    &.pc-flex-hidden {
      display: flex;
      @include breakpoint($bp--xlarge-up) {
        display: none;
      }
    }
    &.mobile-flex-hidden {
      display: none;
      @include breakpoint($bp--xlarge-up) {
        display: flex;
      }
    }
  }
  // @todo investigate these
  .text-align {
    &--left {
      .content-over-media__text-wrapper {
        align-self: flex-start;
      }
    }
    &--right {
      .content-over-media__text-wrapper {
        align-self: flex-end;
      }
    }
    &--center {
      .content-over-media__text-wrapper {
        align-self: center;
      }
    }
  }
  h1 {
    line-height: 0.8;
  }
  // typography defaults
  &__line--eyebrow {
    @include sub-title--bold;
  }
  &__overlay {
    position: absolute;
    top: 50%;
    background-color: $color--full-white;
    // No RTL required. Used for centering.
    left: 50%;
    transform: translate(-50%, -50%);
    &--left {
      #{$rdirection}: auto;
      #{$ldirection}: 20px;
      transform: translateY(-50%);
    }
    &--right {
      #{$rdirection}: 20px;
      #{$ldirection}: auto;
      transform: translateY(-50%);
    }
    &:not([class*='max-width-']) {
      max-width: 49.445%;
    }
  }
  &.overlay-active {
    overflow: visible;
  }
}

// cr21 specific styles moved from lib we no longer need
// @todo remove post holiday
@if $cr21 == true {
  .hero-block__wrapper {
    // @todo investigate these
    .content-over-media__text,
    .text-align--left {
      .content-over-media__text {
        text-align: $ldirection;
      }
    }
    .hero-block__link-wrapper {
      // @todo remove this post holiday - not the correct link style to select in cms
      .link--underline {
        font-family: $ano-bold_regular-font;
        font-size: 14px;
        border-bottom: 1px solid $black;
        span {
          @include icon(icon--chevron-down);
          background: none;
          font-size: 16px;
          font-weight: normal;
          &::after {
            position: absolute;
            bottom: 11px;
            #{$rdirection}: 0;
            @include transform(rotate(-45deg));
            font-size: 26px;
          }
        }
      }
    }
  }
  .hero-block {
    h1 {
      @include title-01();
    }
  }
}
